@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
}
.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/*Track*/
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/*Handel*/
::-webkit-scrollbar-thumb{
  background: #888;
}
/*Handel on Hover*/
::-webkit-scrollbar-thumb:hover{
  background: #555;
}



