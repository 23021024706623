.login-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #edc0e3;
    height: 100vh;  /* Adjusted to fill the entire height */
    margin: 0;
    padding: 0;
}

.login-admin-container {
    width: 620px;  
    background: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    padding: 40px 20px;  
    border-radius: 8px;
}

.login-admin-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.login-admin-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.login-admin-fields input {
    height: 48px;  
    padding: 10px 20px;  
    border: 2px solid #c9c9c9;  
    outline: none;
    color: #333;
    font-size: 16px;  
    border-radius: 5px;
}

.login-admin-container button {
    width: 100%; 
    height: 48px; 
    color: white;
    background-color: #ff4141;  
    margin-top: 20px; 
    border: none;
    border-radius: 5px; 
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.login-admin-login {
    text-align: center;
    margin-top: 20px;
    color: #666;
    font-size: 16px;
}

.login-admin-login span {
    color: #ff4141;
    cursor: pointer;
    font-weight: bold;
}

.login-admin-agree {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}

.login-admin-agree input {
    cursor: pointer;
}